import {
  Button,
  ButtonDesign,
  ComboBox,
  ComboBoxGroupItem,
  ComboBoxItem,
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Form,
  Icon,
  Label,
  MessageStrip,
  MessageStripDesign,
  Modals,
  MultiInput,
  Token,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import compact from 'lodash.compact'
import find from 'lodash.find'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import BusinessPartnerSearchInput from 'components/domains/business-partners/search-input/BusinessPartnerSearchInput'
import { BcaFilterKeys } from 'components/domains/deals/financing/bank-customer-accounts/bca-search-dialog/BcaFilterBar'
import BcaSearchDialog from 'components/domains/deals/financing/bank-customer-accounts/bca-search-dialog/BcaSearchDialog'
import PropertiesSearchDialog from 'components/domains/properties/properties-search/dialog/PropertiesSearchDialog'
import Dialog, { DialogPrimaryButton, DialogSecondaryButton } from 'components/ui/dialog/Dialog'
import MessageBox, { MessageBoxTypes } from 'components/ui/message-box/MessageBox'
import { ConfigContext } from 'hooks/config/useConfig'
import {
  RECEIVABLE_ENDPOINT,
  useComposedCollateralAgreementCreation,
} from 'hooks/services/collaterals/useComposedCollateralAgreementCreation'
import { DealContext } from 'routes/deals/DealContext'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import CagCurrencyFormValue from 'routes/deals/collaterals/creation/CagCurrenyFormValue'
import CagDialogBusinessTypeSwitch from 'routes/deals/collaterals/creation/CagDialogBusinessTypeSwitch'
import CagNameFormItem from 'routes/deals/collaterals/creation/CagNameFormItem'
import CagValueFormItem from 'routes/deals/collaterals/creation/CagValueFormItem'
import styles from 'routes/deals/collaterals/creation/CollateralAgreementCreationDialog.module.css'
import CustomFormItem from 'routes/deals/collaterals/creation/CustomFormItem'
import { useMappedCollateralAgreementTypes } from 'routes/deals/collaterals/creation/useMappedCollateralAgreementTypes'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import { TrancheFilterKeys } from 'routes/deals/financing/shared-components/tranches-search-dialog/TrancheFilterBar'
import TrancheSearchDialog from 'routes/deals/financing/shared-components/tranches-search-dialog/TrancheSearchDialog'

const noManualInputAllowedLength = 0

const realEstateLienCategoryId = '000001'
const defaultValueForNoneRealEstateLiens = 0.01
const defaultCurrencyCode = 'EUR'

const defaultFormValues = {
  cagType: '',
  currencyCode: '',
  cagName: '',
  cagValue: 0,
  provider: { id: '', name: '' },
  newBusinessTranches: [],
  existingBusinessTranches: [],
  bcas: [],
  properties: [],
}

/**
 * @typedef {object} CollateralProvider
 * @property {string} id
 */

/**
 * Checks whether all required parameters for the external collateral are filled
 * @param {object} param
 * @param {string} param.cagName
 * @param {number} param.cagValue
 * @param {string} param.selectedCagType
 * @param {CollateralProvider?} param.selectedProvider
 * @param {object[]?} param.selectedProperties
 * @param {string} param.selectedCurrencyCode
 *
 * @returns {boolean}
 */
const isValidExternalCag = ({
  cagName,
  cagValue,
  selectedCagType,
  selectedProvider,
  selectedProperties,
  selectedCurrencyCode,
} = {}) =>
  !!cagName &&
  !!cagValue &&
  !!selectedCagType &&
  !!selectedProvider?.id &&
  !!selectedProperties?.length &&
  !isEmpty(selectedCurrencyCode.trim())

/**
 * Checks whether all required parameters for the collateral are filled
 * @param {object} param
 * @param {string} param.cagName
 * @param {number} param.cagValue
 * @param {string} param.selectedCagType
 * @param {CollateralProvider?} param.selectedProvider
 * @param {object[]?} param.financialProducts
 * @param {string} param.selectedCurrencyCode
 *
 * @returns {boolean}
 */
const isValidCag = ({
  cagName,
  cagValue,
  selectedCagType,
  selectedProvider,
  financialProductsByDataSourceLength,
  selectedCurrencyCode,
} = {}) =>
  !!cagName &&
  !!cagValue &&
  !!selectedCagType &&
  !!selectedProvider?.id &&
  !!financialProductsByDataSourceLength &&
  !isEmpty(selectedCurrencyCode.trim())

const CollateralAgreementCreationDialog = ({
  open,
  setIsOpen,
  onCancel,
  initialFormValues = defaultFormValues,
}) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.collaterals' })
  const showToast = Modals.useShowToast()

  const [isPropertySearchDialogOpen, setIsPropertySearchDialogOpen] = useState(false)
  const [isTranchesSelectDialogOpen, setIsTranchesSelectDialogOpen] = useState(false)
  const [isAccountsSelectDialogOpen, setIsAccountsSelectDialogOpen] = useState(false)
  const [isRealEstateLien, setIsRealEstateLien] = useState(false)
  const [isErrorMessageBoxOpen, setIsErrorMessageBoxOpen] = useState(false)
  const [isWarningMessageBoxOpen, setIsWarningMessageBoxOpen] = useState(false)
  const [searchParams] = useSearchParams()
  const dataSourceFromSearchParams = useMemo(
    () => searchParams.get('dataSource') ?? DATA_SOURCES.NEW_BUSINESS,
    [searchParams],
  )
  const [businessType, setBusinessType] = useState(dataSourceFromSearchParams)
  const isNewBusiness = useMemo(() => businessType === DATA_SOURCES.NEW_BUSINESS, [businessType])
  const isExistingBusiness = useMemo(
    () => businessType === DATA_SOURCES.EXISTING_BUSINESS,
    [businessType],
  )

  const handleInternalBusinessTypeChange = (_businessType) => {
    setBusinessType(_businessType)
  }

  const { deal } = useContext(DealContext)
  const queryClient = useQueryClient()
  const dealWithRenamedId = useMemo(() => ({ ...deal, id: deal?.dealUuid }), [deal])
  const dealUuid = useMemo(() => deal?.dealUuid, [deal])

  const [createdCollateralId, setCreatedCollateralId] = useState(undefined)

  const redirectToCreatedCollateral = useCallback(
    (_createdCollateralId) => {
      queryClient.invalidateQueries(['deals', dealUuid, 'tranches', 'dataSource'])
      queryClient.invalidateQueries(['collateral-agreements', 'tranche-ids'])
      window
        .open(
          `/${dealDetailPaths.collaterals}/${_createdCollateralId}?dataSource=${businessType}`,
          '_blank',
        )
        .focus()
    },
    [businessType, dealUuid, queryClient],
  )

  const { mutate: createCollateralAgreement, isFetching: isCreatingCag } =
    useComposedCollateralAgreementCreation({
      // trigger a reload of the collaterals after creation by invalidating the query,
      // and open and show a new tab with the newly created cag details
      onSuccess: (createdCollateral) => {
        setCreatedCollateralId(createdCollateral?.id)
        setIsOpen(false)
        showToast({ children: t('collateral-create-success') })
        redirectToCreatedCollateral(createdCollateral?.id)
      },
      onError: ({ createdCollateral, error }) => {
        setIsOpen(false)
        // If we got any error from creating receivables,
        // we want to display a warning, because the CAG is already created.
        if (error?.response?.url?.includes(RECEIVABLE_ENDPOINT)) {
          setCreatedCollateralId(createdCollateral?.id)
          setIsWarningMessageBoxOpen(true)
        } else {
          setIsErrorMessageBoxOpen(true)
        }
      },
    })

  const config = useContext(ConfigContext)

  const {
    data: { cagTypes = [] } = {},
    isLoading: isCagTypesLoading,
    isError: isCagTypesError,
  } = useMappedCollateralAgreementTypes()

  const [selectedCagType, setSelectedCagType] = useState(initialFormValues.cagType)
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(initialFormValues.currencyCode)
  const [cagName, setCagName] = useState(initialFormValues.cagName)
  const [cagValue, setCagValue] = useState(initialFormValues.cagValue)
  const [selectedProvider, setSelectedProvider] = useState(initialFormValues.provider)
  const [selectedNewBusinessTranches, setSelectedNewBusinessTranche] = useState(
    initialFormValues.newBusinessTranches,
  )
  const [selectedExistingBusinessTranches, setSelectedExistingBusinessTranche] = useState(
    initialFormValues.existingBusinessTranches,
  )
  const [selectedBcas, setSelectedBcas] = useState(initialFormValues.bcas)
  const [selectedProperties, setSelectedProperties] = useState(initialFormValues.properties)
  const [isExternalCagType, setIsExternalCagType] = useState(false)

  const resetFormData = useCallback(() => {
    setSelectedCagType(initialFormValues.cagType)
    setSelectedCurrencyCode(initialFormValues.currencyCode)
    setCagName(initialFormValues.cagName)
    setCagValue(initialFormValues.cagValue)
    setSelectedProvider(initialFormValues.provider)
    setSelectedNewBusinessTranche(initialFormValues.newBusinessTranches)
    setSelectedExistingBusinessTranche(initialFormValues.existingBusinessTranches)
    setSelectedBcas(initialFormValues.bcas)
    setSelectedProperties(initialFormValues.properties)
    setIsRealEstateLien(false)
    setIsExternalCagType(false)
  }, [
    initialFormValues.cagType,
    initialFormValues.currencyCode,
    initialFormValues.cagName,
    initialFormValues.cagValue,
    initialFormValues.provider,
    initialFormValues.newBusinessTranches,
    initialFormValues.existingBusinessTranches,
    initialFormValues.bcas,
    initialFormValues.properties,
  ])

  // Clear the selection state of the opposite business type
  useEffect(() => {
    if (isExistingBusiness) {
      setSelectedNewBusinessTranche(initialFormValues.newBusinessTranches)
    } else if (isNewBusiness) {
      setSelectedExistingBusinessTranche(initialFormValues.existingBusinessTranches)
      setSelectedBcas(initialFormValues.bcas)
    }
  }, [
    initialFormValues.bcas,
    initialFormValues.existingBusinessTranches,
    initialFormValues.newBusinessTranches,
    isExistingBusiness,
    isNewBusiness,
  ])

  const [isNotAtLeastOneEbTrancheOrAccountSelected, setIsNotAtLeastOneEbTrancheOrAccountSelected] =
    useState(false)

  useEffect(() => {
    const ebTranchesAndAccountsLength =
      selectedExistingBusinessTranches.length + selectedBcas.length
    if (ebTranchesAndAccountsLength === 0 && isExistingBusiness && !isExternalCagType) {
      setIsNotAtLeastOneEbTrancheOrAccountSelected(true)
    } else if (isNotAtLeastOneEbTrancheOrAccountSelected) {
      setIsNotAtLeastOneEbTrancheOrAccountSelected(false)
    }
  }, [
    isExistingBusiness,
    isExternalCagType,
    isNotAtLeastOneEbTrancheOrAccountSelected,
    selectedBcas.length,
    selectedExistingBusinessTranches.length,
  ])

  const isValid = useMemo(() => {
    if (isExternalCagType) {
      return isValidExternalCag({
        cagName,
        cagValue,
        selectedCagType,
        selectedProvider,
        selectedProperties,
        selectedCurrencyCode,
      })
    }

    let financialProductsByDataSourceLength = selectedNewBusinessTranches.length
    if (isExistingBusiness) {
      financialProductsByDataSourceLength =
        selectedExistingBusinessTranches.length + selectedBcas.length
    }

    return isValidCag({
      cagName,
      cagValue,
      selectedCagType,
      selectedProvider,
      financialProductsByDataSourceLength,
      selectedCurrencyCode,
    })
  }, [
    isExternalCagType,
    selectedNewBusinessTranches,
    isExistingBusiness,
    cagName,
    cagValue,
    selectedCagType,
    selectedProvider,
    selectedCurrencyCode,
    selectedProperties,
    selectedExistingBusinessTranches,
    selectedBcas,
  ])

  const handleCreateClick = () => {
    if (!isValid) return
    const type = find(
      cagTypes,
      ({ name, isGroupHeader }) => name === selectedCagType && !isGroupHeader,
    )
    const typeId = type?.id
    createCollateralAgreement({
      name: cagName,
      type: typeId,
      value: cagValue,
      currency: selectedCurrencyCode,
      collateralProviderId: selectedProvider?.id,
      newBusinessTranches: selectedNewBusinessTranches,
      existingBusinessTranches: selectedExistingBusinessTranches,
      bcas: selectedBcas,
      propertyUuids: isRealEstateLien ? selectedProperties?.map(({ uuid }) => uuid) : [],
      dataSource: businessType,
    })
  }

  const updateSelectedCagType = (newTypeName) => {
    const newType = find(cagTypes, { name: newTypeName })
    const isNewTypeAnExternalType = Object.values(
      config?.collateralAgreements?.externalTypes ?? {},
    ).includes(newType?.id)
    setIsExternalCagType(isNewTypeAnExternalType)
    if (isNewTypeAnExternalType) {
      if (isNewBusiness) {
        setSelectedNewBusinessTranche([])
      } else if (isExistingBusiness) {
        setSelectedExistingBusinessTranche([])
        setSelectedBcas([])
      }
    }

    setSelectedCagType(newTypeName)
    const isNewTypeARealEstateLien = newType?.categoryId === realEstateLienCategoryId
    setIsRealEstateLien(isNewTypeARealEstateLien)
    const shouldApplyDefaultValueForNoneRealEstateLien = !isNewTypeARealEstateLien && !cagValue
    if (shouldApplyDefaultValueForNoneRealEstateLien) {
      setCagValue(defaultValueForNoneRealEstateLiens)
      setSelectedCurrencyCode(defaultCurrencyCode)
    }
  }

  const handleSelectedPropertyDelete = useCallback(({ detail: { token } }) => {
    const selectedId = token.dataset.id
    setSelectedProperties((currentSelectedEntities) =>
      currentSelectedEntities.filter(({ id }) => id !== selectedId),
    )
  }, [])

  const handleFinancialProductDelete = useCallback(
    ({ detail: { token } }) => {
      const selectedId = token.dataset.id
      if (isNewBusiness) {
        setSelectedNewBusinessTranche((prev) =>
          prev.filter(({ trancheId }) => trancheId !== selectedId),
        )
      } else if (isExistingBusiness) {
        setSelectedExistingBusinessTranche((prev) =>
          prev.filter(({ trancheId }) => trancheId !== selectedId),
        )
      }
    },
    [isExistingBusiness, isNewBusiness],
  )

  const selectedProductTokens = useMemo(() => {
    if (isNewBusiness) {
      return selectedNewBusinessTranches.map(({ trancheId, displayId, trancheName }) => (
        <Token
          key={trancheId}
          text={`${trancheName} (${displayId})`}
          data-id={trancheId}
          closeIcon={<Icon name="decline" />}
        />
      ))
    } else if (isExistingBusiness) {
      return selectedExistingBusinessTranches.map(({ trancheId, trancheName }) => (
        <Token
          key={trancheId}
          text={`${trancheName} (${trancheId})`}
          data-id={trancheId}
          closeIcon={<Icon name="decline" />}
        />
      ))
    }
    return []
  }, [
    isExistingBusiness,
    isNewBusiness,
    selectedExistingBusinessTranches,
    selectedNewBusinessTranches,
  ])

  const handleBcaTokenDelete = useCallback(({ detail: { token } }) => {
    const selectedId = token.dataset.id
    setSelectedBcas((prev) => prev.filter(({ bcaId }) => bcaId !== selectedId))
  }, [])

  const selectedBcaTokens = useMemo(
    () =>
      compact(
        selectedBcas?.map(({ bcaId, accountNumber }) => (
          <Token
            key={bcaId}
            text={accountNumber}
            data-id={bcaId}
            closeIcon={<Icon name="decline" />}
          />
        )),
      ),
    [selectedBcas],
  )

  const selectedPropertyTokens = useMemo(
    () =>
      selectedProperties.map(({ id, description }) => (
        <Token
          key={id}
          text={`${description} (${id})`}
          data-id={id}
          closeIcon={<Icon name="decline" />}
        />
      )),
    [selectedProperties],
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    handleCreateClick()
  }

  const initialPropertyInputFilterValues = {
    dealId: dealUuid,
    propertyName: '',
    propertyId: '',
    country: '',
    city: '',
    zipCode: '',
    marketId: '',
  }

  const initiallySelectedTranchesByDataSource = useMemo(() => {
    if (isNewBusiness) {
      return selectedNewBusinessTranches
    } else if (isExistingBusiness) {
      return selectedExistingBusinessTranches
    }
    return []
  }, [
    isExistingBusiness,
    isNewBusiness,
    selectedExistingBusinessTranches,
    selectedNewBusinessTranches,
  ])

  return (
    <>
      <Dialog
        open={open}
        onAfterClose={() => {
          resetFormData()
          setIsOpen(false)
        }}
        headerText={t('create-collateral-agreement')}
        primaryButton={
          <DialogPrimaryButton
            isLoading={isCreatingCag}
            onClick={handleCreateClick}
            disabled={!isValid}
          >
            {tNoPrefix('buttons.create')}
          </DialogPrimaryButton>
        }
        closeButton={
          <DialogSecondaryButton onClick={onCancel}>
            {tNoPrefix('buttons.cancel')}
          </DialogSecondaryButton>
        }
      >
        <Form onSubmit={handleSubmit}>
          <FlexBox
            justifyContent={FlexBoxJustifyContent.Start}
            direction={FlexBoxDirection.Column}
            className={styles.uploadForm}
          >
            <FlexBox
              justifyContent={FlexBoxJustifyContent.SpaceBetween}
              className={styles.columnContainer}
            >
              <CagNameFormItem cagName={cagName} setCagName={setCagName} />
              <CustomFormItem className={styles.columnItem}>
                <Label showColon required for="cag-type-combobox">
                  {t('fields.type')}
                </Label>
                <ComboBox
                  id="cag-type-combobox"
                  value={
                    (isCagTypesError && tNoPrefix('components.loading-combo-box.loading')) ||
                    (isCagTypesLoading && tNoPrefix('components.loading-combo-box.loading')) ||
                    selectedCagType
                  }
                  onFocus={(event) => event.stopPropagation()}
                  onChange={(event) => updateSelectedCagType(event.target.value)}
                >
                  {!isCagTypesError && !isCagTypesLoading && <ComboBoxItem text="" />}
                  {cagTypes.map(({ id, name, isGroupHeader }) =>
                    isGroupHeader ? (
                      <ComboBoxGroupItem text={name} key={id} />
                    ) : (
                      <ComboBoxItem text={name} key={id} />
                    ),
                  )}
                </ComboBox>
              </CustomFormItem>
            </FlexBox>
            <FlexBox
              justifyContent={FlexBoxJustifyContent.SpaceBetween}
              className={styles.columnContainer}
            >
              <CagCurrencyFormValue
                currencyCode={selectedCurrencyCode}
                setCurrencyCode={setSelectedCurrencyCode}
                required
              />
              <CagValueFormItem cagValue={cagValue} setCagValue={setCagValue} required={true} />
            </FlexBox>
            <CustomFormItem>
              <Label showColon required>
                {t('fields.collateral-provider')}
              </Label>
              <BusinessPartnerSearchInput value={selectedProvider} onChange={setSelectedProvider} />
            </CustomFormItem>
            <CustomFormItem>
              <Label showColon required>
                {t('fields.new-and-existing-business')}
              </Label>
              <CagDialogBusinessTypeSwitch
                businessType={businessType}
                onBusinessTypeChange={handleInternalBusinessTypeChange}
              />
            </CustomFormItem>
            {isNotAtLeastOneEbTrancheOrAccountSelected && (
              <CustomFormItem>
                <MessageStrip design={MessageStripDesign.Warning} hideCloseButton>
                  {t('warning')}
                </MessageStrip>
              </CustomFormItem>
            )}
            <CustomFormItem>
              <Label showColon required={!isExternalCagType && isNewBusiness}>
                {t('fields.tranches')}
              </Label>
              <MultiInput
                id="tranches-search-multi-input"
                maxlength={noManualInputAllowedLength}
                className={styles.uploadFormItem}
                tokens={selectedProductTokens}
                icon={
                  <Button
                    id="open-tranches-search-button"
                    icon="value-help"
                    design={ButtonDesign.Transparent}
                    onClick={() => {
                      setIsTranchesSelectDialogOpen(true)
                    }}
                    disabled={isExternalCagType}
                  />
                }
                onTokenDelete={handleFinancialProductDelete}
                disabled={isExternalCagType}
                valueState={
                  isNotAtLeastOneEbTrancheOrAccountSelected ? ValueState.Warning : ValueState.None
                }
                valueStateMessage={<span>{t('warning')}</span>}
              />
            </CustomFormItem>
            {isExistingBusiness && (
              <CustomFormItem>
                <Label showColon required={!isExternalCagType && isNewBusiness}>
                  {t('fields.accounts')}
                </Label>
                <MultiInput
                  id="bcas-search-multi-input"
                  maxlength={noManualInputAllowedLength}
                  className={styles.uploadFormItem}
                  tokens={selectedBcaTokens}
                  icon={
                    <Button
                      id="open-bcas-search-button"
                      icon="value-help"
                      design={ButtonDesign.Transparent}
                      onClick={() => {
                        setIsAccountsSelectDialogOpen(true)
                      }}
                      disabled={isExternalCagType}
                    />
                  }
                  onTokenDelete={handleBcaTokenDelete}
                  disabled={isExternalCagType}
                  valueState={
                    isNotAtLeastOneEbTrancheOrAccountSelected ? ValueState.Warning : ValueState.None
                  }
                  valueStateMessage={<span>{t('warning')}</span>}
                />
              </CustomFormItem>
            )}
            <CustomFormItem>
              <Label showColon required={isExternalCagType}>
                {t('fields.properties')}
              </Label>
              <MultiInput
                maxlength={noManualInputAllowedLength}
                id="multi-properties-input-cag-creation"
                className={styles.uploadFormItem}
                tokens={selectedPropertyTokens}
                disabled={!isRealEstateLien}
                icon={
                  <Button
                    id="open-properties-search-button"
                    icon="value-help"
                    design={ButtonDesign.Transparent}
                    onClick={() => {
                      setIsPropertySearchDialogOpen(true)
                    }}
                  />
                }
                onTokenDelete={handleSelectedPropertyDelete}
              />
            </CustomFormItem>
          </FlexBox>
        </Form>
      </Dialog>
      {createPortal(
        <PropertiesSearchDialog
          isOpen={isPropertySearchDialogOpen}
          setIsOpen={setIsPropertySearchDialogOpen}
          allowMultiSelect
          onAccept={setSelectedProperties}
          initiallySelectedProperties={selectedProperties}
          initialFilterCriteria={initialPropertyInputFilterValues}
        />,
        document.body,
      )}
      {isTranchesSelectDialogOpen && (
        <TrancheSearchDialog
          isOpen={isTranchesSelectDialogOpen}
          setIsOpen={setIsTranchesSelectDialogOpen}
          initiallySelectedTranches={initiallySelectedTranchesByDataSource}
          onSelected={(selectedTranches) => {
            if (isNewBusiness) {
              setSelectedNewBusinessTranche((_) => [...selectedTranches])
            } else if (isExistingBusiness) {
              setSelectedExistingBusinessTranche((_) => [...selectedTranches])
            }
          }}
          initialFilterValues={{
            [TrancheFilterKeys.Deal]: dealUuid,
            [TrancheFilterKeys.BusinessType]: businessType,
          }}
          disabledFilterValues={{
            [TrancheFilterKeys.Deal]: true,
            [TrancheFilterKeys.BusinessType]: true,
          }}
          disabledTrancheIds={[]}
          isMultiSelect={true}
        />
      )}
      {isAccountsSelectDialogOpen && (
        <BcaSearchDialog
          isOpen={isAccountsSelectDialogOpen}
          setIsOpen={setIsAccountsSelectDialogOpen}
          initiallySelectedBcas={selectedBcas}
          onSelected={setSelectedBcas}
          initialFilterValues={{
            [BcaFilterKeys.Deal]: dealWithRenamedId,
          }}
          readOnlyFilters={[BcaFilterKeys.Deal]}
          isMultiSelect={true}
        />
      )}
      <MessageBox
        id="cag-create-error-message-box"
        type={MessageBoxTypes.Error}
        open={isErrorMessageBoxOpen}
        onClose={() => setIsErrorMessageBoxOpen(false)}
        titleText={t('collateral-create-error-title')}
      >
        {t('collateral-create-error')}
      </MessageBox>
      <MessageBox
        id="cag-create-warning-message-box"
        type={MessageBoxTypes.Warning}
        open={isWarningMessageBoxOpen}
        onClose={() => {
          setIsWarningMessageBoxOpen(false)
          redirectToCreatedCollateral(createdCollateralId)
        }}
        titleText={t('collateral-create-warning-title')}
      >
        {t('collateral-create-warning')}
      </MessageBox>
    </>
  )
}

CollateralAgreementCreationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialFormValues: PropTypes.shape({
    cagType: PropTypes.string,
    currencyCode: PropTypes.string,
    cagName: PropTypes.string,
    cagValue: PropTypes.number,
    provider: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    trancheIds: PropTypes.arrayOf(PropTypes.string),
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
  }),
}

export default CollateralAgreementCreationDialog
